/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import logo from "../../images/cove-logo2.png"

// import { FaHeart, FaFacebookSquare, FaTelegram } from 'react-icons/fa';
// import PaymentIcon from '../GallerySelector/PaymentIcon';
import Footer from '../Footer/Footer'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

const Layout = ({ children }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navbar color="white" light expand="md">
        <NavbarBrand href="/" className="cove-brand">
          <img src={logo} alt="tht-logo" className="logo"/>
          Cove
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/">主頁</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/">立即預約</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about-us/">關於我們</NavLink>
            </NavItem>
            {/*<NavItem>
              <NavLink href="/other-services/">常見問題</NavLink>
              <NavLink href="/other-services/">其他服務</NavLink>
            </NavItem>*/}
          </Nav>
          {/*<NavbarText>Simple Text</NavbarText>*/}
        </Collapse>
      </Navbar>
      <div>{children}</div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
