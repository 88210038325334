import React, {useState} from "react"
import { FaHeart, FaFacebookSquare, FaTelegram } from 'react-icons/fa';
import PaymentIcon from '../GallerySelector/PaymentIcon';
import { Link } from "gatsby"
import {
  Container,
  Col,
  Row
} from "reactstrap"

const Footer = () => (
  <footer className="footer footer-black footer-white">
    <Container>
      <Row>
        <Col xs="12" md="3">
          <div className="col-title">
            聯絡我們
          </div>
          <ul>
            <li>
              <div className="contacts-label email-label">
                電郵: <a href="mailto:hello@covedrive.com">hello@covedrive.com</a>
              </div>
            </li>
            <li>
              <div className="contacts-label phone-label">
                電話: <a href="tel:+85267138809">+852 6713 8809</a>
              </div>
            </li>
            <li>
              <div className="contacts-label whatsApp-label">
                Whatsapp: <a href="https://wa.me/85267138809">+852 6713 8809</a>
              </div>
            </li>
          </ul>
        </Col>

        <Col xs="12" md="3">
          <div className="col-title">
            關於我們
          </div>
          <ul>
            <li>
              <Link to="/about-us/">關於Cove</Link>
            </li>
            <li>
              <Link to="/faq/">常見問題</Link>
            </li>
          </ul>
        </Col>

        <Col xs="12" md="3">
          <div className="col-title">
            條款及細則
          </div>
          <ul>
            <li>
              <Link to="/terms/">條款及細則</Link>
            </li>
            {/*<li>
              <Link to="/giftcard-terms/">禮品卡條款</Link>
            </li>*/}
            {/*<li>
              <Link to="/privacy/">私隱政策</Link>
            </li>*/}
          </ul>
          <div className="col-title">
            社交媒體
          </div>
          <ul className="social-icons-ul">
            <li className="social-icons-li">
              <a href="https://www.facebook.com/covedrive/">
                <FaFacebookSquare className="social-icon" size="1.6rem"/>
              </a>
            </li>
            {/*<li className="social-icons-li">
              <a href="http://blog.creative-tim.com/?ref=pkr-footer">
                <FaTelegram className="social-icon" size="1.6rem"/>
              </a>
            </li>*/}
          </ul>
        </Col>

        <Col xs="12" md="3">
          <div className="col-title">
            支付方式
          </div>
          <ul className="payment-icons-ul">
            <li className="payment-icons-li">
              <PaymentIcon id="applepay" style={{width: 50, marginBottom: 8}}/>
            </li>
            <li className="payment-icons-li">
              <PaymentIcon id="googlepay" style={{width: 50, marginBottom: 8}}/>
            </li>
            <li className="payment-icons-li">
              <PaymentIcon id="visa" style={{width: 50, marginBottom: 8}}/>
            </li>
            <li className="payment-icons-li">
              <PaymentIcon id="master" style={{width: 50, marginBottom: 8}}/>
            </li>
            <li className="payment-icons-li">
              <PaymentIcon id="amex" style={{width: 50, marginBottom: 8}}/>
            </li>

          </ul>


        </Col>
      </Row>

      <Row>
        <div className="credits mx-auto">
          <span className="copyright">
            © {new Date().getFullYear()}, made with{" "}
            {/*<i className="fa fa-heart heart" />*/}
            <FaHeart className="heart"/> by Cove
          </span>
        </div>
      </Row>
    </Container>
  </footer>
)


export default Footer
