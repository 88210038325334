import React from 'react';
import visa from 'payment-icons/min/flat/visa.svg';
import master from 'payment-icons/min/flat/mastercard.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import applepay from '../../images/icons/applepay.svg';
import googlepay from '../../images/icons/google_pay.svg';


const PaymentIcon = ({
  style, className, id
}) => {

  let icon;

  switch (id) {
    case "visa":
      icon = visa
      break;
    case "master":
      icon = master
      break;
    case "amex":
      icon = amex
      break;
    case "applepay":
      icon = applepay
      break;
    case "googlepay":
      icon = googlepay
      break;
    default:

  }

  return (
    <img
      src={icon}
      style={style}
      className={className}
      alt={""}
    />
  )
}

export default PaymentIcon;
